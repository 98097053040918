import React, { useEffect } from 'react';

type WindowWithProSeal = {
  provenExpert: { proSeal: (config: Record<string, unknown>) => void };
} & Window;

declare const window: WindowWithProSeal;

const ProsealScript = () => {
  useEffect(() => {
    function loadProSeal() {
      if (window.provenExpert) {
        window.provenExpert.proSeal({
          widgetId: '57a4942d-05d7-48b0-91d4-2d70c46548be',
          language: document.documentElement.lang,
          usePageLanguage: true,
          bannerColor: '#0DB1CD',
          textColor: '#FFFFFF',
          showBackPage: true,
          showReviews: true,
          hideDate: true,
          hideName: false,
          googleStars: false,
          displayReviewerLastName: false,
          embeddedSelector: '#proSealWidget',
        });
      }
    }

    const script = document.createElement('script');

    script.src = 'https://s.provenexpert.net/seals/proseal-v2.js';
    script.async = true;
    script.addEventListener('load', loadProSeal);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <noscript>
        <a
          href="https://www.provenexpert.com/provenexpert-com/?utm_source=seals&utm_campaign=embedded-proseal&utm_medium=profile&utm_content=57a4942d-05d7-48b0-91d4-2d70c46548be"
          target="_blank"
          title="Customer reviews & experiences for ProvenExpert.com"
          className="pe-pro-seal-more-infos"
          rel="nofollow noreferrer"
        >
          More info
        </a>
      </noscript>

      <div id="proSealWidget" style={{ zIndex: 1 }} />
    </>
  );
};

export default ProsealScript;

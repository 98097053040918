import { defineMessages } from 'react-intl';

const messages = defineMessages({
  col1Title: { id: 'modules-expertDirectory-footer-col1Title' },
  ratingSeals: { id: 'modules-expertDirectory-footer-ratingSeals' },
  customerSurveys: { id: 'modules-expertDirectory-footer-customerSurveys' },
  advantages: { id: 'modules-expertDirectory-footer-advantages' },
  enterpriseSolution: { id: 'modules-expertDirectory-footer-enterpriseSolution' },
  affiliateProgram: { id: 'modules-expertDirectory-footer-affiliateProgram' },
  awards: { id: 'modules-expertDirectory-footer-awards' },
  col2Title: { id: 'modules-expertDirectory-footer-col2Title' },
  whyProvenexpert: { id: 'modules-expertDirectory-footer-whyProvenexpert' },
  ourCompany: { id: 'modules-expertDirectory-footer-ourCompany' },
  team: { id: 'modules-expertDirectory-footer-team' },
  careers: { id: 'modules-expertDirectory-footer-careers' },
  testimonials: { id: 'modules-expertDirectory-footer-testimonials' },
  contact: { id: 'modules-expertDirectory-footer-contact' },
  col3Title: { id: 'modules-expertDirectory-footer-col3Title' },
  knowledge: { id: 'modules-expertDirectory-footer-knowledge' },
  faq: { id: 'modules-expertDirectory-footer-faq' },
  customerReviewPlatforms: { id: 'modules-expertDirectory-footer-customerReviewPlatforms' },
  customerReviews: { id: 'modules-expertDirectory-footer-customerReviews' },
  customerSatisfaction: { id: 'modules-expertDirectory-footer-customerSatisfaction' },
  reviewGuidelines: { id: 'modules-expertDirectory-footer-reviewGuidelines' },
  col4Title: { id: 'modules-expertDirectory-footer-col4Title' },
  press: { id: 'modules-expertDirectory-footer-press' },
  newsletter: { id: 'modules-expertDirectory-footer-newsletter' },
  onlineDemo: { id: 'modules-expertDirectory-footer-onlineDemo' },
  expertRating: { id: 'modules-expertDirectory-footer-expertRating' },
  expertDirectory: { id: 'modules-expertDirectory-footer-expertDirectory' },
  copyright: { id: 'modules-expertDirectory-footer-copyright' },
  termsAndConditions: { id: 'modules-expertDirectory-footer-termsAndConditions' },
  privacyPolicy: { id: 'modules-expertDirectory-footer-privacyPolicy' },
  qualityAssurance: { id: 'modules-expertDirectory-footer-qualityAssurance' },
  legalNotice: { id: 'modules-expertDirectory-footer-legalNotice' },
  events: { id: 'modules-expertDirectory-footer-events' },
});

export default messages;

import PeHidden from '@pe-libs/react-components/build/components/PeHidden';
import PeLoader from '@pe-libs/react-components/build/components/PeLoader';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useRedirect from '#hooks/useRedirect';
import { getExpertCompassGlobalLoading } from '#modules/expertDirectory/selectors/global';
import BottomLinks from './BottomLinks';
import ColumnLinks from './ColumnLinks';
import { dataColumnLinks, dataSocials, dataBottomLinks } from './Helper';
import ProsealScript from './ProsealScript';
import Socials from './Socials';
import useStyles from './styles';
import WidgetRichSnippet from './WidgetRichSnippet';

const Footer: FC = () => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const { urlToLivePage } = useRedirect();
  const loading = useSelector(getExpertCompassGlobalLoading);

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.linksContainer}>
            {dataColumnLinks(formatMessage, urlToLivePage).map((column) => (
              <ColumnLinks key={column.title} title={column.title} data={column.data} />
            ))}
            <PeHidden smDown={true}>
              <div>
                <ProsealScript />
              </div>
            </PeHidden>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.bottomLeftContainer}>
              <WidgetRichSnippet />
              <Socials data={dataSocials} />
            </div>
            <BottomLinks data={dataBottomLinks(formatMessage, urlToLivePage)} />
          </div>
        </div>
      </footer>
      <PeLoader open={loading} />
    </>
  );
};

export default Footer;
